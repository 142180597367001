<template>
    <div>
        <div class="mt-10" :class="$vuetify.breakpoint.mobile ? '' : 'container'">
            <v-row>
                <v-col cols="12" lg="3" md="4" sm="6" class="px-sm-10 d-flex justify-center" v-for="(item, index) in links.filter(x => checkAuth(x))" :key="index">
                    <div class="text-center">
                        <v-card class="primary home-card d-flex justify-center" flat :to="item.slug">
                            <v-avatar
                                v-if="item.badge && item.badgeValue > 0"
                                class="badge white--text"
                                color="warning"
                                size="26"
                            >{{ item.badgeValue }}</v-avatar>
                            <v-icon color="secondary" class="home-icon">{{ item.icon }}</v-icon>
                        </v-card>
                        <h3 class="third--text mt-2">{{item.title}}</h3>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            links: [
                {
                    title: "الاحصائيات",
                    slug: "/dashboard",
                    icon: "fi fi-br-chart-histogram",
                    badge: false,
                    badgeValue: 0,
                    auth: ["administrator", "dashboard"],
                },
                {
                    title: "الطلبات",
                    slug: "/orders-tabs",
                    icon: "fi fi-sr-shopping-cart",
                    badge: true,
                    badgeValue: 0,
                    auth: ["administrator", "orders"],
                },
                {
                    title: "الرسائل",
                    slug: "/chats",
                    icon: "fi fi-sr-envelope",
                    badge: true,
                    badgeValue: 0,
                    auth: ["administrator", "chat"],
                },
                {
                    title: "الزبائن",
                    slug: "/clients",
                    icon: "fi fi-br-users",
                    badge: true,
                    badgeValue: 0,
                    auth: ["administrator", "client"],
                },
                {
                    title: "المنتجات",
                    slug: "/categories",
                    icon: "fi fi-br-shopping-bag",
                    badge: false,
                    badgeValue: 0,
                    auth: ["administrator", "category"],
                },
                {
                    title: "الشكاوي والمقترحات",
                    slug: "/complaint",
                    icon: "fi fi-br-shield-exclamation",
                    badge: true,
                    badgeValue: 0,
                    auth: ["administrator", "complaint"],
                },
                {
                    title: "العروض",
                    slug: "/offers",
                    icon: "mdi-gift",
                    badge: false,
                    badgeValue: 0,
                    auth: ["administrator", "offers"],
                },
                {
                    title: "الاخبار والمناسبات",
                    slug: "/news",
                    icon: "fi fi-br-cloud-share",
                    badge: false,
                    badgeValue: 0,
                    auth: ["administrator", "news"],
                },
                {
                    title: "الاعلانات",
                    slug: "/slider",
                    icon: "fi fi-br-megaphone",
                    badge: false,
                    badgeValue: 0,
                    auth: ["administrator", "slider"],
                },
                {
                    title: "الحسابات",
                    slug: "/accounts",
                    icon: "fi fi-br-dollar",
                    badge: false,
                    visible: false,
                    auth: ["administrator"],
                },
                {
                    title: "المستخدمين",
                    slug: "/users",
                    icon: "fi fi-br-users-alt",
                    badge: false,
                    badgeValue: 0,
                    auth: ["administrator", "users"],
                },
                {
                    title: "الإعدادات",
                    slug: "/settings",
                    icon: "mdi-cog-outline",
                    renderComponent: true,
                    badgeValue: 0,
                    auth: ["administrator", "settings"],
                },
            ],
            dashboards: {},
        };
    },

    created() {
        this.getDashboard();
    },

    methods: {
        async getDashboard() {
            this.$global.state.loading = true;
            try {
                const res = await this.$http.get(`Dashboards/homePage`);
                this.dashboards = res.data.result.clientsCount;
                this.links[1].badgeValue = res.data.result.newOrdersCount;
                this.links[2].badgeValue = res.data.result.unreadMessagesCount;
                this.links[3].badgeValue = res.data.result.newClientsCount;
                this.links[5].badgeValue =
                    res.data.result.unReplayComplaintsCount;
            } catch (err) {
                this.$store.dispatch("toastification/setToast", {
                    message: `${err.data.message}`,
                    type: "error",
                });
            } finally {
                this.$global.state.loading = false;
            }
        },

        checkAuth(item) {
            for (var i = 0; i < item.auth.length; i++) {
                if(this.$store.state.auth.userData.roles.find(x => x == item.auth[i])){
                    return true;
                }
            }
            return false;
        },
    },
};
</script>


<style lang="scss" scoped>
.container {
    width: 70%;
    margin: auto;
}
.badge {
    position: absolute;
    top: -10px;
    right: 0;
    font-weight: 900;
    font-family: cursive;
}
</style>














